import classNames from "classnames";
import React from "react";
import styles from "./SubTable.module.scss";
import Table from "./Table";

const SubTable = React.memo(({cellClassName, headerContainerClassName, headerCellClassName, headerRowClassName, rowClassName, rowsContainerClassName, headerHandleClassName, ...rest}) => {
    return <Table {...rest}
                  className={styles.base}
                  cellClassName={classNames(styles['cell'], cellClassName)}
                  headerContainerClassName={classNames(styles['headers-group-container'], headerContainerClassName)}
                  headerCellClassName={classNames(styles['header-cell'], headerCellClassName)}
                  headerRowClassName={classNames(styles['header-row'], headerRowClassName)}
                  headerHandleClassName={classNames(styles['header-handle'], headerHandleClassName)}
                  rowClassName={classNames(styles['row'], rowClassName)}
                  rowsContainerClassName={classNames(styles['rows-container'], rowsContainerClassName)}
    />
});

export default SubTable;
