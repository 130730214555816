import React, { useState } from "react";
import styles from "./ExpandingComponent.module.scss";
import classNames from "classnames";
import {UnmountClosed} from 'react-collapse';

export default React.memo(({children, Component = "button", className, style, headerClassName, disabled, ButtonChildren, defaultValue = false, contentClassName, contentStyle, headerStyle, componentProps}) => {
    const [isOpen, setIsOpen] = useState(defaultValue);
    return <div className={className} style={style}>
        <Component disabled={disabled} className={classNames(styles.header, headerClassName)} style={headerStyle} {...componentProps} onClick={() => {
            if(disabled) {
                return;
            }
            setIsOpen(!isOpen)
        }}>
            <ButtonChildren isOpen={!disabled && isOpen}/>
        </Component>
        <UnmountClosed isOpened={disabled ? false : isOpen} theme={{collapse: styles['animation']}}>
            <div className={classNames(styles.content, contentClassName)} style={contentStyle}>
                {children}
            </div>
        </UnmountClosed>
    </div>
});
