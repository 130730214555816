import {useLayoutEffect} from "react"

let count = 0;
let originalScroll = 0;
const disableScroll = () => {
    document.documentElement.scrollTop = originalScroll;
    return false;
};

export default () => {
    useLayoutEffect(() => {
        if(count === 0){
            originalScroll = document.documentElement.scrollTop;
            document.addEventListener('scroll', disableScroll);
        }
        count = count + 1;
        return () => {
            count = count - 1;
            if(count === 0){
                document.removeEventListener('scroll', disableScroll);
            }
        };
    }, []);

    return "";
};
