import _ from "lodash";
import styles from "./Table.module.scss";
import NotAvailableCellData from "./NotAvailableCellData";
import React from "react";

export default ({value}) => {
    let pValue = value;
    if (_.isArray(value)) {
        pValue = value.join(", ");
    }
    return <>
        {pValue === "N/A" && <NotAvailableCellData/>}
        {pValue && pValue!=="N/A" && <span className={styles['cell-value']} title={pValue.toString()}>{pValue}</span>}
    </>
};
