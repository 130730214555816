import styles from "./animations.module.scss";

export const standardEasing = "cubic-bezier(.25,.8,.25,1)";
export const standardDuration = 400;

export const slideLtrClassNames = {
    enter: styles['ltr-enter'],
    enterActive: styles['ltr-enter-active'],
    exit: styles['ltr-exit'],
    exitActive: styles['ltr-exit-active']
};

export const slideLtrAbsoluteClassNames = {
    enter: styles['ltr-absolute-enter'],
    enterActive: styles['ltr-absolute-enter-active'],
    exit: styles['ltr-absolute-exit'],
    exitActive: styles['ltr-absolute-exit-active']
};

export const slideRtlClassNames = {
    enter: styles['rtl-enter'],
    enterActive: styles['rtl-enter-active'],
    exit: styles['rtl-exit'],
    exitActive: styles['rtl-exit-active']
};

export const slideRtlAbsoluteClassNames = {
    enter: styles['rtl-absolute-enter'],
    enterActive: styles['rtl-absolute-enter-active'],
    exit: styles['rtl-absolute-exit'],
    exitActive: styles['rtl-absolute-exit-active']
};


export const slideTopToBottomClassNames = {
    enter: styles['top-to-bottom-enter'],
    enterActive: styles['top-to-bottom-enter-active'],
    exit: styles['top-to-bottom-exit'],
    exitActive: styles['top-to-bottom-exit-active']
};

export const fadeClassNames = {
    enter: styles['fade-enter'],
    enterActive: styles['fade-enter-active'],
    exit: styles['fade-exit'],
    exitActive: styles['fade-exit-active']
};

export const height = styles.height;
