import React from "react";
import styles from "./TabLayout.module.scss"
import _ from "lodash";
import classNames from "classnames";

const InnerNav = React.memo(({children: _children = []}) => {
    const children = _.filter(_.isArray(_children) ? _children : [_children], _.identity);

    return <div className={styles.nav}>
        {children.map((child, index) => {
            return React.cloneElement(child, {key: index, activeClassName: styles['active-link'], className: classNames(styles['link'], child.props?.className)}, child.props?.children);
        })}
    </div>
});

export default InnerNav;
