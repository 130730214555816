import React from "react";
import classNames from "classnames";
import styles from "./Table.module.scss";

export default React.memo(({ stickColumnIndex, stickyColumnStyleRef, columns, widths }) => {
    return <div className={classNames(styles['row'], styles['blank'])}>
        {columns.map((column, index) => {

            const className = classNames(styles['cell'], column.cellClass, { [stickyColumnStyleRef.current.dataset.styleid]: index <= stickColumnIndex });
            const style = {
                flex: column.fixedWidth ? `0 0 ${widths[index]}px` : `1 0 ${widths[index]}px`
            };

            return <div key={column.id} className={className} style={style}>
                <span />
            </div>
        })}
    </div>
});
